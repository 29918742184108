import { useTranslation } from '@app/lib/hooks/useTranslation'
import { metaSetter } from '@app/utils'
import { MarketingIntro } from '@app/components/stateless/marketing/intro-rest'
import { MarketingTrustBy } from '@app/components/stateless/marketing/trusted'
import { MarketingDrawer } from '@app/components/general'
import { MarketingDashboard } from '@app/components/stateless/marketing/dashboard'
import { MarketingBenefits } from '@app/components/stateless/marketing/benefits'
import { MarketingIntroCoverage } from '@app/components/stateless/marketing/intro-coverage'
import loadNamespaces from 'next-translate/loadNamespaces'
import { MarketingWorld } from '@app/components/stateless/marketing/marketing-world'
import { MarketingStats } from '@app/components/stateless/marketing/marketing-stats'
import { FeaturesSlimList } from '@app/components/stateless/marketing/features-slim'
import { FILTERED_MARKETING } from '@app/configs/app-config'
import { useAuthContext } from '@app/components/providers/auth'
// import { IntroBenches } from '@app/components/stateless/marketing/intro-benches'
// import { SectionContainer } from '@app/components/stateless/containers/section-container'

export async function getStaticProps(ctx: any) {
  return {
    props: await loadNamespaces({
      ...ctx,
      pathname: '/',
    }),
  }
}

function Index() {
  const { t, lang } = useTranslation('home')
  const { account } = useAuthContext()

  return (
    <MarketingDrawer authenticated={account.authed} loading={!account.inited}>
      <MarketingIntro t={t} lang={lang} />
      <FeaturesSlimList t={t} lang={lang} />
      <MarketingDashboard t={t} lang={lang} />
      {/* <SectionContainer>
        <IntroBenches t={t} />
      </SectionContainer> */}
      <MarketingWorld t={t} />
      <MarketingStats t={t} />
      <MarketingIntroCoverage t={t} />

      {FILTERED_MARKETING ? (
        <></>
      ) : (
        <>
          <MarketingTrustBy t={t} />
          <MarketingBenefits t={t} />
        </>
      )}
    </MarketingDrawer>
  )
}

export default metaSetter(
  { Index },
  {
    translation: 'home',
    rest: false,
  }
)
